// The application does not use a header or navbar at this time, but if at some point it does, you can raise it here
const Header = () => {
  return (
    <header>
      <nav>
      </nav>
    </header>
  )
}

export default Header;