
import logo from '../assets/images/logo.png';
import "swiper/css";
const EnConstruccion = () => {
  return <>
   <div className="home-swiper_container">
    <div className='box-slide'>
            <div className="image-contain">
              <div className="gradient-top"></div>
              <div className="gradient-bottom"></div>
              <div className="details-slide">
                <img src={logo} alt="Sky Gym" />
                <h2>SITIO EN CONSTRUCCIÓN</h2>
              </div>
            </div>
          </div>
    </div>
  </>;
}

export default EnConstruccion;